import React from "react";

interface DotProps {
	size: number;
	name?: string;
	opacity?: number;
}

/**
 * Single Dot component
 */
export const Dot = ({ name, size, opacity = 0.4 }: DotProps) => (
	<div
		data-id={name}
		sx={{
			height: size,
			width: size,
			backgroundColor: "iconLight",
			borderRadius: "50%",
			opacity,
			transform: `scale(${opacity ? 1 : 0})`,
			transition: "all 200ms ease-in-out",
		}}
	/>
);

export const sizeSwitch = (activeElement: number, index: number) => {
	const d2 = Math.abs(activeElement - index);
	switch (d2) {
		case 0:
			return 8;
		case 1:
			return 6;
		default:
			return 4;
	}
};
